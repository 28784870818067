<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      stroke="currentColor"
      d="M12.4008 22C17.8342 22 22.2388 17.5228 22.2388 12C22.2388 6.47715 17.8342 2 12.4008 2C6.96738 2 2.56274 6.47715 2.56274 12C2.56274 17.5228 6.96738 22 12.4008 22Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      stroke="currentColor"
      d="M15.3522 16L12.9773 13.586C12.6083 13.211 12.4009 12.7024 12.4008 12.172V6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
